.zahlungsarten_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 50px 0;
    min-height: 100vh;
}

.zahlungsarten_subcontainer{
    width: 90%;
}

.zahlungsarten_subcontainer h4{
    color: rgb(95, 32, 32);
    text-align: center;
    font-size: 20px;
    margin-top: 50px;
}