.header_container {
  display: flex;
  justify-content: space-between;
  height: 20vh;
  background: linear-gradient(0deg, #0D7410,#002E13);
}

.header_link{
  display: flex;
  justify-content: flex-end;
}

.header_shopping_car {
  font-size: 40px;
  color: rgb(255, 254, 254);
  margin: 35px 20px 0 0;
}

.header_logo_container{
  margin: 0 0 0 50px
}

.header_logo {
  width: 77px;
  height: 77px;
  margin: 10px 0 0 40px
}
