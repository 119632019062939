.wiederrufsformular_container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wiederrufsformular_subcontainer{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}   

.wiederrufsformular_subcontainer h4{
    font-size: 18px;
    color: rgb(95, 32, 32);
    font-family: sans-serif;
}

.wiederrufsformular_subcontainer a{
    text-decoration: none;
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    margin-top: 30px;
    color: rgb(95, 32, 32);    
}