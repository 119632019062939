.agb_container{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.agb_subcontainer{
    width: 90%;
}

.agb_subcontainer h1{
    font-size: 20px;
    text-align: center;
    color: rgb(95, 32, 32);
    margin-top: 30px;
}

.agb_subcontainer h2{
    font-size: 18px
}

.agb_subcontainer p{
    font-size: 15px;
}

.agb_p_container{
    width: 100%;
}