.datenschutz_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.datenschutz_subcontainer{
    width: 90%;
    margin-bottom: 50px;
}

.datenschutz_subcontainer h1{
    font-size: 20px;
    margin-top: 50px;
    text-align: center;
    color: rgb(95, 32, 32);

}

.datenschutz_subcontainer h2{
font-size: 16px;
}