.wiederrufserklaerung_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.wiederrufserklaerung_subcontainer{
    width: 90%;
}

.wiederrufserklaerung_subcontainer h1{
    color: rgb(95, 32, 32);
    font-size: 20px;
    text-align: center;
    margin-top: 50px
}

.wiederrufserklaerung_subcontainer h2{
    font-size: 16px;
}

.wiederrufserklaerung_subcontainer h3{
    font-size: 14px;
}
